import request from "./request";
/**
 * 统一api管理
 * @param data
 * @returns {AxiosPromise}
 */

//获取各类字典列表 
export function getDictType(dictType) {
    return request({
        url:"/system/dict/data/list",
        method: "post",
        data:{dictType}
    });
}
// 获取服务热线等系统配置
export function getDevice() {
    return request({
        url:"/module/setting/list",
        method: "post",
    });
}
//获取广告位图片
export function getMenuPic(menu) {
    return request({
        url:"/module/menubanner/query",
        method: "post",
        data:{menu}
    });
}
//场地列表
export function getPlaceList(data) {
    return request({
        url:"module/place/list/v2",
        method: "post",
        data:data
    });
}
// 场地详情
export function getPlaceDetail(id) {
    return request({
        url:"/module/place/detail",
        method: "post",
        data:{id}
    });
}
// 收藏场地
export function addFavorites(placeId) {
    return request({
        url:"/module/member/favorites/add",
        method: "post",
        data:{placeId}
    });
}
// 取消收藏
export function removeFavorites(placeId) {
    return request({
        url:"/module/member/favorites/place/remove",
        method: "post",
        data:{placeId}
    });
}
// 获取城市列表
export function getCityList(data) {
    return request({
        url:"/module/city/query",
        method: "post",
        data:data
    });
}
// 获取热门分类
export function getHotSort() {
    return request({
        url:"/module/hotcategories/query",
        method: "post",
    });
}
// 获取专题列表
export function getTopicList(data) {
    return request({
        url:"/module/topic/list",
        method: "post",
        data:data
    });
}
//获取专题详情
export function getTopicDetail(topicId) {
    return request({
        url:"/module/topic/place/list/v2",
        method: "post",
        data:{topicId}
    });
}
// 获取文章列表
export function getArticleList(data) {
    return request({
        url:"/module/article/list",
        method: "post",
        data:data
    });
}
// 获取文章详细信息
export function getArticleDetail(id) {
    return request({
        url:"/module/article/detail",
        method: "post",
        data:{id}
    });
}
// 获取合作品牌
export function getCooperator() {
    return request({
        url:"/module/cooperator/query",
        method: "post",
    });
}
// 园区招商
export function getParkList(data) {
    return request({
        url:"/module/investment/list",
        method: "post",
		data:data
    });
}
//查询友情链接列表
export function getLinks() {
    return request({
        url:"/module/links/query",
        method: "post",
    });
}
//获取登陆验证码
export function getSmsCode(tel) {
    return request({
        url:"/module/system/sms/login",
        method: "post",
		data:{tel},
    });
}
//手机号登录
export function Login(data) {
    return request({
        url:"/module/member/login/phone",
        method: "post",
		data:data,
    });
}
// 获取用户信息
export function getUserInfo() {
    return request({
        url:"/module/member/detail",
        method: "post",
    });
}
// 获取普通验证码
export function getSms(tel) {
    return request({
        url:"/module/system/sms/verify",
        method: "post",
		data:{tel}
    });
}
// 修改会员信息
export function editMember(data) {
    return request({
        url:"/module/member/edit",
        method: "post",
		data:data,
    });
}
// 发布需求
export function publish(data) {
    return request({
        url:"/module/demand/add",
        method: "post",
		data:data
    });
}
//我的订单
export function myOrders(data) {
    return request({
        url:"/module/demand/order/list",
        method: "post",
		data:data
    });
}
// 我的收藏
export function myFavorites(data) {
    return request({
        url:"/module/member/favorites/list",
        method: "post",
		data:data
    });
}
// 我的发布
export function myPubulish(data) {
    return request({
        url:"/module/demand/publish/list",
        method: "post",
		data:data
    });
}
// 我的消息
export function myNews(data) {
    return request({
        url:"/module/message/list",
        method: "post",
		data:data
    });
}
// 删除文件
export function removeFile(ids) {
    return request({
        url:"/module/attachment/remove",
        method: "post",
		data:{ids}
    });
}
//提交反馈
export function feedBack(data) {
    return request({
        url:"/module/suggestion/add",
        method: "post",
		data:data
    });
}
// 获取单页信息
export function getSheetpage(categoryCode) {
    return request({
        url:"/module/sheetpage/detail",
        method: "post",
		data:{categoryCode}
    });
}
// 增加咨询记录
export function addrRecord(data) {
    return request({
        url:"/module/browsinghistory/add",
        method: "post",
		data:data
    });
}