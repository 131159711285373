import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter);


const routes = [
    {
        path: "/",
        name: "home",
        meta:{
            title:"首页"
        },
        component: () => import("@/pages/home.vue")
    },
    {
        path: "/space",
        name: "space",
        meta:{
            title:"全部场地"
        },
        component: () => import("@/pages/space.vue"),
    },
    {
        path: "/introduction",
        name: "introduction",
        meta:{
            title:"场地详情"
        },
        component: () => import("@/pages/introduction.vue"),
    },
    {
        path: "/topic",
        name: "topic",
        meta:{
            title:"专题推荐"
        },
        component: () => import("@/pages/topic.vue"),
    },
	{
	    path: "/topicDetail",
	    name: "topicDetail",
	    meta:{
	        title:"专题详情"
	    },
	    component: () => import("@/pages/topicDetail.vue"),
	},
    {
        path: "/service",
        name: "service",
        meta:{
            title:"服务案例"
        },
        component: () => import("@/pages/service.vue"),
    },
    {
        path: "/dynamic",
        name: "dynamic",
        meta:{
            title:"最新动态"
        },
        component: () => import("@/pages/dynamic.vue"),
    },
    {
        path: "/passage",
        name: "passage",
        meta:{
            title:"文章详情"
        },
        component: () => import("@/pages/passage.vue"),
    },
    {
        path: "/park",
        name: "park",
        meta:{
            title:"园区招商"
        },
        component: () => import("@/pages/park.vue"),
    },
	{
		path: "/aboutUs",
        name: "aboutUs",
        meta:{title:"关于我们"},
        component: () => import("@/pages/aboutUs.vue"),
	},
	{
		path: "/personal",
        name: "personal",
        meta:{title:"关于我们"},
        component: () => import("@/pages/personal.vue"),
	},
	{
		path: "/publish",
	    name: "publish",
	    meta:{title:"发布需求"},
	    component: () => import("@/pages/publish.vue"),
	},
	{
		path: "/myOrder",
	    name: "myOrder",
	    meta:{title:"我的订单"},
	    component: () => import("@/pages/myOrder.vue"),
	},
	{
		path: "/myCollect",
	    name: "myCollect",
	    meta:{title:"我的收藏"},
	    component: () => import("@/pages/myCollect.vue"),
	},
	{
		path: "/myNews",
	    name: "myNews",
	    meta:{title:"我的消息"},
	    component: () => import("@/pages/myNews.vue"),
	},
	{
		path: "/feedback",
	    name: "feedback",
	    meta:{title:"意见反馈"},
	    component: () => import("@/pages/feedback.vue"),
	},
	{
		path: "/sheetPage",
	    name: "sheetPage",
	    meta:{title:"协议内容"},
	    component: () => import("@/pages/sheetPage.vue"),
	},
	{
		path: "/myPublish",
	    name: "myPublish",
	    meta:{title:"我的发布"},
	    component: () => import("@/pages/myPublish.vue"),
	},
];

const router = new VueRouter({
    mode:'history',
    routes,
    scrollBehavior(to,from,savedPosition){
        if(savedPosition){
            return savedPosition;
        }else{
            return { x : 0, y : 0};
        }
    }
});

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = process.env.VUE_APP_BASE_NAME + '-' + to.meta.title
    }
    next()
})

export default router;
