import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userToken:'',
        userInfo:{},
		layer:'',
    },
    mutations: {
        setUserToken(state,payload){
            state.userToken = payload
        },
        setUserInfo(state,payload){
            state.userInfo = payload
        },
		setlayer(state,payload){
		    state.layer = payload
		},
    },
    actions: {
        // 退出系统
        LogOut({ commit }) {
            return new Promise((resolve) => {
                commit("setUserToken","")
                commit("setUserInfo",{})
				commit("setlayer","")
				resolve()
            })
        },
    },
    getters: {

    },
    modules: {},
    plugins:[
        createPersistedState({
            storage:window.sessionStorage
        })
    ],
});
