<template>
	<div class="containerbox">
		<div class="container">
			<div class="linebox flex">
				<img class="logo" src="../../public/images/logo_01.png" />
				<div class="top-rigt-box flex">
					<div class="midle flex">
						<div class="place-box flex jump" @click="showCities()">
							<div class="city">{{nowCity}}</div>
							<img class="arrow" src="../../public/images/arrow_01.png" />
						</div>
						<div class="search-box flex">
							<div class="flex serch">
								<img class="search-pic" src="../../public/images/search_01.png" />
								<input class="keyword" placeholder="场地名称/关键字" @keyup.enter="search()"
									v-model="srearchData.searchValue" />
							</div>
							<button class="search-btn" @click="search()">搜索</button>
							<div v-if="!userInfo.id" @click="showLayer('login')" class="loginbtn">登录</div>
						</div>
					</div>
					<div class="flex" :class="userInfo.id?'rightBox':''" >
						<div v-if="userInfo.id" class="flex usertips">
							<img v-if="userInfo.avatar" class="avatar" :src="userInfo.avatar" />
							<div v-else class="avatar"></div>
							<el-dropdown>
								<span class="usermenu jump flex">
									{{userInfo.nickname || tel}}
									<i class="userarrow el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item @click.native="toPage('personal')">会员中心</el-dropdown-item>
									<el-dropdown-item @click.native="logOut()">退出登录</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="nav-box flex">
			<div class="box flex">
				<div class="nav flex">
					<div v-for="(item,index) in navlist" :key="index">
						<div :class="index==curent?'nav-item-bg':''" class="nav-item"
							@click="setIndex(index),pageto(index)">
							{{item.name}}
						</div>
					</div>
				</div>
				<div class="nav-right flex">
					<a href="publish">
						<div class="flex nav-right-item">
							<img class="nav-pic" src="../../public/images/edit_01.png" />
							<div class="nav-tilte">发布需求</div>
						</div>
					</a>
					<div class="flex nav-right-item" @click="showLayer('stay')">
						<img class="nav-pic-home" src="../../public/images/home_1.png" />
						<div class="nav-tilte">场地入驻</div>
					</div>
					<div class="nav-right-item service_tel">服务热线：{{service_tel}}</div>
				</div>
			</div>
		</div>
		<!-- 选择城市 -->
		<div class="showCity" v-if="showCity">
			<div v-for="(item,index) in city" :key="index">
				<div class="cityName jump" @click="chooseCity(item.name)">{{item.name}}</div>
			</div>
		</div>
		<div class="index-layer" v-show="layer">
			<div class="index-layer-stay" v-show="layer==='stay'">
				<img class="index-stay-pic" :src="menuPic" />
				<img @click="showLayer('')" class="index-stay-close" src="../../public/images/close_01.png" />
			</div>
			<div class="layer-login" v-show="layer==='login'">
				<div class="login-list">
					<div class="login-title">验证码登录</div>
					<div class="login-item flex">
						<img class="login-pic" src="../../public/images/phone_01.png" />
						<input class="login-intex" maxlength="11" placeholder="请输入手机号" v-model="loginData.tel" />
					</div>
					<div class="login-item flex">
						<img class="login-pic" src="../../public/images/code_01.png" />
						<input class="login-intex" maxlength="4" placeholder="请输入验证码" @keyup.enter="login()"
							v-model="loginData.captcha" />
						<div class="login-get jump" @click="getCode()">{{tips}}</div>
					</div>
					<div class="login-tips ">
						请认真阅读并充分理解<span @click="toSheetPage('sheetPage',2)" class="tips jump">服务协议</span> 和
						<span @click="toSheetPage('sheetPage',3)" class="tips jump">隐私政策</span>注册/登录代表您已同意该条款。
					</div>
					<div class="login-bigbtn jump" @click="login()">登录</div>
				</div>
				<img @click="showLayer('')" class="login-close" src="../../public/images/close_01.png" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	import store from '@/store'
	export default {
		data() {
			return {
				// 默认城市
				nowCity: '',
				tel: '',
				menuPic: '',
				srearchData: {
					searchValue: '',
				},
				showCity: false,
				tips: '获取验证码',
				time: 60,
				curent: 0,
				service_tel: '',
				layer: '',
				city: [],
				navlist: [{
						name: '首页',
						href: '/'
					},
					{
						name: '全部场地',
						href: 'space'
					},
					{
						name: '专题推荐',
						href: 'topic'
					},
					{
						name: '服务案例',
						href: 'service'
					},
					{
						name: '最新动态',
						href: 'dynamic'
					},
					{
						name: '园区招商',
						href: 'park'
					},
					{
						name: '关于我们',
						href: 'aboutUs'
					},
				],
				loginData: {
					tel: '',
					captcha: '',
					from: 'PC',
				},
			}
		},
		computed: {
			...mapState(['userInfo']),
		},
		mounted() {
			console.log(this.userInfo, '用户信息');
			if (this.userInfo.id) {
				this.tel = this.userInfo.tel.substr(0, 3) + '****' + this.userInfo.tel.substr(7, 4);
			}
			this.layer = store.state.layer,
			//获取系统配置
			this.$api.getDevice().then(res => {
				console.log(res, '系统配置');
				this.service_tel = res.data.service_tel;
			});
			// 获取场地入驻图片
			this.$api.getMenuPic('场地入驻').then(res => {
				console.log(res, '场地入驻');
				this.menuPic = res.data[0].picture;
			});
			// 显示城市
			if (this.$route.query.city) {
				localStorage.setItem('city', this.$route.query.city);
			}
			this.nowCity = localStorage.getItem('city') || '北京';
			// 获取城市列表
			this.getCities();
		},
		methods: {
			showLayer(layer) {
				this.$store.commit('setlayer', layer);
				this.layer = store.state.layer;
			},
			setIndex(index, Keyword) {
				this.curent = index;
				// this.nowCity = city || '北京';
				this.srearchData.searchValue = Keyword;
			},
			pageto(index) {
				this.$router.push({
					path: this.navlist[index].href,
				});
			},
			//会员中心
			toPage(page) {
				this.$router.push({
					path: page,
				});
			},
			search() {
				if (this.srearchData.searchValue) {
					this.$router.push({
						path: 'space',
						query: this.srearchData,
					});
				} else {
					this.$alert('请输入关键字', '系统提示')
				}
			},
			// 获取城市列表
			getCities() {
				var data = {
					orderByColumn: 'letter',
					isAsc: 'asc'
				}
				// console.log(data,'城市传参')
				this.$api.getCityList(data).then(res => {
					console.log(res, '城市列表')
					this.city = res.data.city;
				});
			},
			//展示城市选项
			showCities() {
				this.showCity = this.showCity == true ? false : true;
			},
			// 选择城市
			chooseCity(name) {
				localStorage.setItem("city", name);
				location.reload();
			},
			// 获取登录验证码
			getCode() {
				if (this.time == 60) {
					if (!(/^1[3|4|5|8|6|7|9][0-9]\d{8}$/.test(this.loginData.tel))) {
						alert('请输入可以联系到您的电话号码');
						return;
					}
					this.$api.getSmsCode(this.loginData.tel).then(res => {
						console.log(res, '验证码返回');
						var timer = setInterval(res => {
							this.time = this.time - 1;
							this.tips = this.time + 's后重新获取'
							// console.log(this.time);
							if (this.time <= 0) {
								clearInterval(timer);
								this.time = 60;
								this.tips = '获取验证码';
							}
						}, '1000')
					});
				} else {
					console.log('请等待计时')
				}
			},
			//验证码登录
			login() {
				this.$api.Login(this.loginData).then(res => {
					console.log(res, '登录接口返回');
					this.$store.commit('setUserToken', res.data.token);
					// 获取用户信息
					this.$api.getUserInfo().then(userRes => {
						console.log(userRes, '用户信息');
						this.$store.commit('setUserInfo', userRes.data);
						// alert('登录成功');
						this.showLayer('');
					});
				});
			},
			// 退出登录
			logOut() {
				this.$store.commit('setUserToken', "");
				this.$store.commit('setUserInfo', {});
				this.$store.commit('setlayer', "");
			},
			// 协议详情
			toSheetPage(page, categoryCode) {
				store.commit('setlayer', '');
				this.$router.push({
					path: page,
					query: {
						categoryCode: categoryCode,
					}
				});
			},
		},
	}
</script>

<style scoped>
	.containerbox {
		background-color: #FFFFFF;
	}

	.linebox {
		width: 1100px;
		padding-top: 8px;
		padding-bottom: 8px;
		background-color: #FFFFFF;
		margin: 0 auto;
		/* border:solid 1px ; */
	}

	.logo {
		margin-left: 10px;
		width: 173px;
		height: 35px;
	}

	.arrow {
		margin-left: 8.5px;
		width: 10px;
		height: 5px;
	}

	.place-box {
		margin-left: 20px;
		max-width: 130px;
		/* border:solid 1px; */
	}

	.city {
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #EF942F;
	}
	
	.top-rigt-box{
		width: 100%;
		margin-left: 90px;
		justify-content: space-between;
		/* border: solid 1px; */
	}
	
	.midle{
		
	}
	
	.search-box {
		margin-left: 30px;
		height: 25px;
	}
	
	.serchLong{
		width: 500px;
		height: 25px;
		background: #FFFFFF;
		box-shadow: 0px 1px 4px 0px rgba(25, 25, 25, 0.1);
	}
	.serch {
		width: 436px;
		height: 25px;
		background: #FFFFFF;
		box-shadow: 0px 1px 4px 0px rgba(25, 25, 25, 0.1);
	}

	.search-pic {
		margin-left: 20px;
		width: 12px;
		height: 12px;
	}

	.keyword {
		height: 12px;
		line-height: 12px;
		font-size: 10px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #999999;
		width: 80%;
		margin-left: 12px;
	}

	.search-btn {
		margin-left: 30px;
		width: 49px;
		height: 25px;
		background: #004098;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 25px;
	}

	.rightBox {
		margin-right: 0px;
		justify-content: space-between;
	}
	
	.usertips {
		margin-left: 20px;
	}

	.avatar {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		background-image: url(../../public/images/head_01.png);
		background-size: 100% 100%;
	}

	.usermenu {
		max-width: 110px;
		margin-left: 10px;
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
	}

	.userarrow {
		margin-left: 10px;
		margin-right: 5px;
	}


	.loginbtn {
		margin-left: 20px;
		margin-right: 10px;
		width: 49px;
		height: 25px;
		background: #004098;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 25px;
		text-align: center;
	}

	.nav-box {
		width: 100%;
		margin: 0 auto;
		height: 36px;
		background: #004098;
		font-size: 10px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FEFEFE;
	}
	
	.box{
		width: 1100px;
		margin: 0 auto;
		/* border: solid 1px; */
	}
	
	.nav {
		/* border: solid 1px; */
		width: 700px;
	}

	.nav-item {
		padding-left: 22px;
		padding-right: 22px;
		text-align: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FEFEFE;
		height: 36px;
		line-height: 36px;
		cursor: pointer;
	}

	.nav-item-bg {
		background-color: #EF942F;
	}

	.nav-right {
		width: 400px;
		/* border: solid 1px; */
		margin-left: 30px;
		justify-content: space-between;
	}

	.nav-right-item {
		/* margin-right: 25px; */
		height: 36px;
		line-height: 36px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FEFEFE;
		cursor: pointer;
	}

	.service_tel {
		/* border: solid 1px; */
	}

	.nav-pic {
		width: 13px;
		height: 13px;
	}

	.nav-pic-home {
		width: 13px;
		height: 13px;
	}

	.nav-tilte {
		margin-left: 3.5px;
		height: 36px;
		line-height: 36px;
	}

	.showCity {
		position: absolute;
		top: 40px;
		left: 27%;
		background-color: #FFFFFF;
		padding-top: 5px;
		padding-bottom: 5px;
		border-radius: 5px;
	}

	.cityName {
		/* width: 100px; */
		padding: 10px 20px 10px 20px;
	}

	.cityName:hover {
		background-color: #f5f7fa;
	}


	.layer-login {
		position: absolute;
		top: 20%;
		left: 50%;
		transform: translate(-50%, -20%);
		width: 404px;
		height: 372px;
		background: #FFFFFF;
		border-radius: 20px;
		padding-top: 42px;
		padding-left: 37px;
		padding-right: 37px;
		padding-bottom: 38px;
	}

	.login-title {
		margin: auto auto 7px auto;
		width: 90px;
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #004098;
		padding-bottom: 12px;
		border-bottom: solid 3px #004098;
	}

	.login-item {
		margin-top: 28px;
		width: 330px;
		padding-bottom: 20px;
		border-bottom: #DDDDDD solid 1px;
	}

	.login-pic {
		width: 20px;
		height: 20px;
	}

	.login-intex {
		margin-left: 10px;
		width: 90px;
		line-height: 20px;
	}

	.login-close {
		position: absolute;
	}

	.login-get {
		margin-left: 113px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #004098;
	}

	.login-tips {
		/* width: 230px; */
		/* width: 100%; */
		margin: 21px auto 0 auto;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 18px;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.tips {
		color: #004098;
		border-bottom: solid 1px;
	}

	.login-bigbtn {
		margin: 33px auto 0 auto;
		text-align: center;
		font-size: 17px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 41px;
		width: 281px;
		height: 41px;
		background: #004098;
		border-radius: 22px;
	}

	.login-close {
		position: absolute;
		top: 410px;
		left: 50%;
		transform: translate(-50%, 0);
		width: 35px;
		height: 35px;
	}
</style>
