<template>
  <div id="app">
    <!-- <Header ref="Header"/> -->
    <router-view :key="$route.fullPath" />
    <Footer/>
  </div>
</template>

<script>

import  Header from '@/components/header.vue';
import  Footer from '@/components/footer.vue';

export default {
  name: 'App',
  components:{
    Header,
    Footer,
  },
  data(){
    return {
    }
  },
  mounted() {
  },
  methods:{
  }
}
</script>

<style scoped >
.rightside {
  font-weight: bold;
  width: auto;
}
.rightside .nav-item{
  /* width: 53px;
  height: 72px; */
  width: 65px;
  height: 76px;
  border-bottom: 1px solid #d9e6ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nav-item:last-child{
  border: none;
}
.nav-item .img{
  /* width: 33px; */
  width: 40px;
  height: auto;
}
.nav-item .wz{
  color: #4465aa;
  margin-top: 6px;
}
</style>
