<template>
	<div class="bigbox">
		<div class="line">
			<div class="tips">LANNG SPACE 私人顾问为您寻找最合适的场地</div>
			<div class="btn jump" @click="consult()">立即咨询</div>
		</div>
		<div class="list">
			<div class="list-box flex">
				<div class="left">
					<div class="navbox">
						<div class="nav-line flex">
							<div v-for="(item,index) in navlist" :key="index">
								<div class="nav-item flex">
									<!-- <a :href="item.href"></a> -->
									<div @click="pageTo(item.href,item.name)" class="nav-item-name jump">{{item.name}}
									</div>
									<div v-if="index<(navlist.length-1)" class="shu">|</div>
								</div>
							</div>
						</div>
					</div>
					<div class="linkbox row">
						<div class="link-yqlj">友情链接</div>
						<div class="link-list row">
							<div v-for="(item,index) in link" :key="index">
								<div class="link-name flex">
									<a :href="item.link">
										<div class="link">{{item.name}}</div>
									</a>
									<div class="nav-shu" v-show="(index+1)%7!=0">|</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="right-tips">全国服务热线（10:00-18:00）</div>
					<div class="tel-box flex">
						<img class="tel-pic" src="../../public/images/tel_01.png" />
						<div class="tel-num">{{sysData.service_tel}}</div>
					</div>
					<div class="address">地址：{{sysData.company_address}}</div>
					<div class="address">网址：<a style="color: #FFFFFF;"
						:href="sysData.company_website">{{sysData.company_website}}</a>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom">
			{{sysData.registration_number}}
		</div>
	</div>
</template>

<script>
	import {
		Notification,
		MessageBox,
		Message
	} from 'element-ui'
	import store from '@/store'
	export default {
		data() {
			return {
				layer: '',
				// 系统数据
				sysData: {},
				navlist: [{
						name: '首页',
						href: '/'
					},
					{
						name: '全部场地',
						href: 'space'
					},
					{
						name: '专题推荐',
						href: 'topic'
					},
					{
						name: '服务案例',
						href: 'service'
					},
					{
						name: '最新动态',
						href: 'dynamic'
					},
					{
						name: '场地入住',
						href: '#'
					},
				],
				link: [],
			};
		},
		mounted() {
			//获取系统配置
			this.$api.getDevice().then(res => {
				console.log(res, '系统配置');
				this.sysData = res.data;
				// this.service_tel = res.data.service_tel;
			});
			// 友情链接
			this.$api.getLinks().then(res => {
				console.log(res, '友情链接');
				this.link = res.data.slice(0, 20);
			});
		},
		methods: {
			pageTo(page, name) {
				if (name === '场地入住') {
					this.$store.commit('setlayer', 'stay');
					// console.log(store.state.layer);
					location.reload();
				} else {
					this.$router.push({
						path: page,
					})
				}
			},
			consult() {
				var data = {
					placeId: 0,
					placeName: 'LANNG SPACE 私人顾问为您寻找最合适的场地',
				}
				this.$api.addrRecord(data).then(res => {
					console.log(res, '增加咨询记录')
					if (res) {
						MessageBox.confirm('系统已经收到您的咨询申请，我们将尽快与您联系', '系统提示', {
							confirmButtonText: '确定',
				 		type: 'success'
						})
					}
				})
			}
		},
	};
</script>

<style>
	.line {
		padding-top: 25.5px;
		padding-bottom: 17px;
		width: 100%;
		background-image: url(../../public/images/bg_01.png);
		background-size: 100% 100%;
		border: solid 1px;
	}

	.tips {
		width: 100%;
		text-align: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 20px;
	}

	.btn {
		width: 100px;
		height: 25px;
		margin: 0 auto;
		margin-top: 20.5px;
		border: 1px solid #FFFFFF;
		background-color: #184b95;
		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 25px;
		text-align: center;
	}

	.list {
		width: 100%;
		background-color: #333333;
	}

	.list-box {
		width: 1100px;
		margin: 0 auto;
		/* height: 125px; */
		font-family: PingFang SC;
		color: #FFFFFF;
		padding-top: 25px;
		padding-bottom: 30px;
	}

	.left {
		margin-left: 30px;
		padding-right: 10px;
		/* border: solid 1px #FFFFFF; */
	}

	.nav-line {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		/* justify-content: space-between; */
	}
	.shu{
		margin-left: 20px;
		margin-right: 20px;
	}
	.nav-item {
		justify-content: flex-start;
	}

	.nav-item-name {
		/* margin-right: 10px; */
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
	}

	.nav-shu {
		margin-left: 20px;
		margin-right: 20px;
	}

	.linkbox {
		margin-top: 21.5px;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		justify-content: space-between;
	}

	.link-yqlj {
		/* margin-top: -6px; */
		/* border: solid #FFFFFF; */
	}

	.link-list {
		width: 500px;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-left: 30px;
	}

	.link-name {
		font-family: PingFang SC;
		font-weight: 400;
		margin-bottom: 5px;
	}

	.link {
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.right {
		margin-top: -5px;
		margin-left: 80px;
		height: 95px;
		border-left: solid 1px #1c1c1c;
		padding-left: 110px;
	}

	.right-tips {
		margin-top: -8px;
	}

	.tel-box {
		justify-content: flex-start;
	}

	.tel-pic {
		margin-top: 10px;
		width: 15px;
		height: 15px;
	}

	.tel-num {
		font-size: 21px;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		color: #518DE4;
		margin-left: 10px;
		margin-top: 10px;
	}

	.address {
		margin-top: 7px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
	}

	.bottom {
		width: 100%;
		padding-top: 13px;
		padding-bottom: 13px;
		background-color: #222222;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 36px;
	}
</style>
